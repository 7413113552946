<template>
  <div class="stack">
    <div class="stack-info">
      <div class="stack-info__content">
        <page-title class="stack-info__title">
          {{ $t('title.server') }}
        </page-title>
        <div :class="{ visible: true }" class="stack-info__create">
          <base-button class="stack-info__create-btn" @click="newImage()">
            {{ $t('newServer') }}
          </base-button>
          <div class="stack-mode"></div>
        </div>
      </div>
    </div>

    <base-loader v-if="isRequest" class="open-ticket__loader"> </base-loader>

    <page-block v-else-if="!isRequest">
      <base-empty v-if="list.length === 0" title="Новый сервер" class="cloud-info__empty">
        <template #link>
          <base-button>
            {{ 'Новый образ' }}
          </base-button>
        </template>
      </base-empty>
      <tariffs-table-image />
    </page-block>
  </div>
</template>

<script>
import TariffsTableImage from '../../Main/components/TariffsTableImage';
import BaseEmpty from '@/components/BaseEmpty/BaseEmpty';
import storeMixin from '../../../mixins/index';
import newImage from '@/mixins/newImage';
export default {
  name: 'ViewImage',
  components: {
    TariffsTableImage,
    BaseEmpty,
  },
  mixins: [storeMixin, newImage],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      timerCnt: 1,
      isRequest: true,
    };
  },
  computed: {
    isCreating() {
      return this.$store.state.moduleStack.privateImages.find(x => x.status.code === '38');
    },
  },
  mounted() {
    this.getOpenStackApiKey()
      .then(() => {
        this.newFetch();
      })
      .catch(error => {
        this.showError(error);
        setTimeout(() => this.$router.push({ name: 'Login' }), 400);
      });
    this.$store.dispatch('moduleStack/fetchPrivateImages').then(data => {
      // console.log(data.images.map(x => x.status));
      let imagesId = data.images
        .filter(
          x =>
            x.status === 'saving' ||
            x.status === 'queued' ||
            x.status === 'importing' ||
            x.status === 'uploading'
        )
        .map(x => x.id);
      if (imagesId.length > 0) {
        imagesId.forEach(x => {
          // console.log(x);
          this.checkStatus(_, x);
        });
      }
    });
  },
  methods: {
    getOpenStackApiKey() {
      return this.$store.dispatch('moduleStack/getOpenStackApiKey', this.id);
    },
    validateOpenStackApiKey() {
      return this.$store.dispatch('moduleStack/validateOpenStackApiKey', this.id);
    },
    async checkStatus(callback, id) {
      this.timerCnt = 1;
      this.timerId = setTimeout(() => {
        this.$store.dispatch('moduleStack/checkImageStatus', id).then(async data => {
          // console.log(data);
          if (!['active'].includes(data)) {
            this.res = data;
            await this.checkStatus(callback, id);
          } else {
            this.timerCnt = 1;
            this.isProcessing = false;
            clearTimeout(this.timerId);
            this.res = data;
            return 'success';
          }
        });
      }, 5000 * this.timerCnt);
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "title": {
      "server": "Образы",
      "stats": "Статистика"
    },
    "sure": {
      "confirm": "Создать"
    },
    "prolong": {
      "confirm" : "Сменить",
      "order": "Отмена"
    },
    "newServer": "Новый образ",
    "activeUnder": "Действует до {date}",
    "actions": {
      "prolong": "Продлить",
      "changePlan": "Сменить тариф"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.stack {
  display: flex;
  flex-direction: column;
  min-size(100%);
  flex: 1 1 100%;
  &-info {
    +breakpoint(sm-and-up) {
      flexy(space-betweeb, center);
    }

    &__content {
      flexy(space-between, center);

      +breakpoint(sm-and-up) {
        justify-content: flex-start;
        margin-top: 1.5rem
        //margin-right: 1.5rem;
        flex: 1 1 auto;
      }
    }

    &__loader {
      flexy(center, center);
      flex: 1 1 auto;
    }

    &__create {
      flex: 0 0 auto;
      margin-top: 1.5rem;
      max-width: 160px;

      +breakpoint(sm-and-up) {
        margin: 0 0 0 auto;
      }
    }
  }

  &-mode {
    flexy(flex-end, center);
    margin-left: auto;

    +breakpoint(sm-and-up) {
      justify-content: flex-start;
      margin-left: 1.5rem;
    }

    &__btn {
      & + & {
        margin-left: 1rem;
      }
    }
  }

  &-content {
    margin-top: 1.5rem;

    +breakpoint(sm-and-up) {
      margin-top: 2rem;
    }
    +breakpoint(md-and-up) {
      margin-top: 2.5rem;
    }
  }
}
.tariff-plan {

  &__btn {
    min-width: 160px;
    & + & {
      margin-top: 14px;

      +breakpoint(sm-and-up) {
        margin-top: 0;
        margin-left: 1.5rem;
      }
    }
  }
}
</style>
